import React from 'react'
import SbEditable from 'storyblok-react'
import StoryblokClient from 'storyblok-js-client'
import isNode from 'is-node'

import {
  parseStoryblokProductToAlgolia,
  parseStoryblokArticleToAlgolia,
} from 'src/algolia/clienthelpers'

import * as styles from './editor.module.scss'
import Components from '../../storyblok/Components'

const resolve_relations = [
  'product_list.products',
  'product_grid.products',
  'article_list.articles',
  'product_and_article.product',
  'product_and_article.article',
  'product_and_article.article',
  'page_product.suggestions_articles',
  'page_product.related_sizes',
  'quote_and_product.product',
  'text_and_product.product',
]

const getQueryParam = (param: string): string => {
  if (!isNode) {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get(param) as string
  } else {
    return ''
  }
}

const getParam = function (val: any) {
  let result = ''
  let tmp = []

  window.location.search
    .substring(1)
    .split('&')
    .forEach(function (item) {
      tmp = item.split('=')
      if (tmp[0] === val) {
        result = decodeURIComponent(tmp[1])
      }
    })

  return result
}

// loadStory is triggered on init and on input change in storyblok
// if the story uses references in storyblok, they will be fetched on every loadStory
//
// if the story uses some kind of post data where we fetch all of a type, that data will be
// fetched on init with fetchPostsBySlug and added to extraContent

class StoryblokEntry extends React.Component {
  storyblok: any
  editMode = true

  constructor(props: any) {
    super(props)

    // TODO: Activate
    // this.validateUser()

    this.state = { story: null, extraContent: { globalProduct: {} } }
  }

  validateUser = () => {
    const validationString =
      getQueryParam('_storyblok_tk[space_id]') +
      ':' +
      getQueryParam('_skey') +
      ':' +
      getQueryParam('_storyblok_tk[timestamp]')
    const validationToken = crypto
      .createHash('sha1')
      .update(validationString)
      .digest('hex')
    if (
      getQueryParam('_storyblok_tk[token]') == validationToken &&
      parseInt(getQueryParam('_storyblok_tk[timestamp]')) >
        Math.floor(Date.now() / 1000) - 3600
    ) {
      // you're in the edit mode.
      this.editMode = true
    }
  }

  loadStoryblokBridge = (cb: any) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    if (this.editMode) {
      script.src = `//app.storyblok.com/f/storyblok-latest.js?t=${getQueryParam(
        '_skey'
      )}`
      script.onload = cb
      document.getElementsByTagName('head')[0].appendChild(script)
    }
  }

  componentDidMount() {
    if (this.editMode) {
      this.storyblok = new StoryblokClient({
        accessToken: getQueryParam('_skey'),
        cache: {
          clear: 'auto',
          type: 'memory',
        },
      })
      this.loadStoryblokBridge(() => {
        this.initStoryblokEvents()
      })
    }
  }

  async fetchPostsByComponent(component: string, sort_by = '') {
    let posts: any = []

    await this.storyblok
      .get('cdn/stories', {
        by_slugs: '*',
        sort_by: sort_by,
        per_page: 100,
        filter_query: {
          component: {
            in: component,
          },
        },
      })
      .then((response: any) => {
        if (response && response.data && response.data.stories) {
          posts = response.data.stories
        }
      })

    return posts
  }

  // Moved to fetchPostsByComponent
  // async fetchPostsBySlug(slug: string, parser?: any, filter_query?: any) {
  //   let posts: any = []

  //   await this.storyblok
  //     .get('cdn/stories', {
  //       by_slugs: slug,
  //       sort_by: 'published_at',
  //       per_page: 100,
  //       filter_query: filter_query ? filter_query : {},
  //     })
  //     .then((response: any) => {
  //       if (response && response.data && response.data.stories) {
  //         posts = parser
  //           ? response.data.stories.map((story: any) => parser(story))
  //           : response.data.stories
  //       }
  //     })

  //   return posts
  // }

  async fetchSiblings(story: any) {
    let starts_with = story.full_slug
    let links: any = []
    if (!story.is_startpage) {
      starts_with = story.full_slug.replace(story.slug, '')
    }
    await this.storyblok
      .get('cdn/links/', {
        starts_with: starts_with,
      })
      .then((response: any) => {
        if (response && response.data && response.data.links) {
          links = Object.values(response.data.links).map((link: any) => ({
            slug: link.slug,
            name: link.name,
          }))
        }
      })
      .catch((error: any) => {
        console.log(error)
      })

    return links
  }

  loadProductConfig(lang: string) {
    const language = lang === 'default' ? '' : lang + '/'
    window.storyblok.get(
      {
        slug: `${language}site-config/product`,
        version: 'draft',
      },
      (data: any) => {
        this.setState({ extraContent: { globalProduct: data.story.content } })
      }
    )
  }

  async loadProducers(fullSlug?: string) {
    const extraContent = this.state.extraContent

    const producers = await this.fetchPostsByComponent('page_producer')
    let filteredProducers = []

    if (fullSlug) {
      filteredProducers = producers.filter((producer) =>
        producer.full_slug.startsWith(fullSlug)
      )
    } else {
      filteredProducers = producers
    }

    extraContent['producers'] = filteredProducers
    this.setState({ extraContent: extraContent })
  }

  async loadCountries() {
    const extraContent = this.state.extraContent

    const countries = await this.fetchPostsByComponent('page_country')

    extraContent['countries'] = countries
    this.setState({ extraContent: extraContent })
  }

  async loadRegions(fullSlug?: string) {
    const extraContent = this.state.extraContent

    const regions = await this.fetchPostsByComponent('page_region')
    let filteredRegions = []

    if (fullSlug) {
      filteredRegions = regions.filter((region) =>
        region.full_slug.startsWith(fullSlug)
      )
    } else {
      filteredRegions = regions
    }

    extraContent['regions'] = filteredRegions
    this.setState({ extraContent: extraContent })
  }

  async loadContentNav(story: any) {
    story.content.navItems = await this.fetchSiblings(story)
    this.setState({ story: story })
  }

  async loadArticleCategoryData(story: any) {
    const extraContent = this.state.extraContent

    const articleCategories = await this.fetchPostsByComponent(
      'page_article_category'
    )

    // Get categories
    const topCategories = articleCategories
      // Filter out top categories only
      .filter((categoryPage) => {
        return categoryPage.full_slug && !categoryPage.full_slug.split('/')[2]
      })
      .map((categoryPage) => {
        const title = categoryPage.name ? categoryPage.name : ''
        const slug = categoryPage.full_slug || ''
        const category = categoryPage.content.category || ''
        const isTopCategory =
          categoryPage.full_slug && categoryPage.full_slug.split('/')[1]
            ? false
            : true
        return {
          title,
          slug,
          category,
          isTopCategory,
        }
      })

    // Get articles
    const pageCategory = story?.content?.category ? story.content.category : ''
    let pageArticles = []

    const articles = await this.fetchPostsByComponent(
      'page_article',
      'position'
    )

    if (articles?.length > 0) {
      if (pageCategory) {
        pageArticles = articles.filter((article) => {
          return article.content.category === pageCategory
        })
      } else {
        pageArticles = articles
      }

      // Get tags
      let pageTags: [] = []

      if (pageArticles.length > 0) {
        pageArticles.forEach((article) => {
          if (article.content.tags && article.content.tags.length > 0) {
            article.content.tags.forEach((tag) => {
              if (!pageTags.includes(tag)) pageTags.push(tag)
            })
          }
        })
      } else {
        pageTags = []
      }
      extraContent['topCategories'] = topCategories
      extraContent['articles'] = pageArticles.map((article) =>
        parseStoryblokArticleToAlgolia(article)
      )
      extraContent['tags'] = pageTags
      this.setState({ extraContent: extraContent })
    }
  }

  async loadProductCategoryData(story: any) {
    const extraContent = this.state.extraContent

    // Get categories
    const categories = await this.fetchPostsByComponent('page_category')

    const pageRootCategory =
      story.full_slug && story.full_slug.split('/')[1]
        ? story.full_slug.split('/')[1]
        : null

    // Get page category navigation tree
    // Filter out top categories only
    const topCategories = categories
      .filter((categoryPage) => {
        return categoryPage.full_slug && !categoryPage.full_slug.split('/')[2]
      })
      .map((categoryPage) => {
        const title = categoryPage.name ? categoryPage.name : ''
        const slug = categoryPage.full_slug || ''
        const category = categoryPage.content.category || ''
        const isTopCategory =
          categoryPage.full_slug && categoryPage.full_slug.split('/')[1]
            ? false
            : true
        return {
          title,
          slug,
          category,
          isTopCategory,
        }
      })

    // Filter out categories by root level category
    const subCategories =
      categories.length > 0 && pageRootCategory
        ? categories
            .filter((categoryPage) => {
              return (
                categoryPage.full_slug &&
                categoryPage.full_slug.split('/')[1] === pageRootCategory
              )
            })
            .map((categoryPage) => {
              const title = categoryPage.name ? categoryPage.name : ''
              const slug = categoryPage.full_slug || ''
              const category = categoryPage.content.category || ''
              const isRootCategory = categoryPage.is_startpage || false
              return {
                title,
                slug,
                category,
                isRootCategory,
              }
            })
        : []

    extraContent['topCategories'] = topCategories
    extraContent['categories'] = subCategories

    // Get products
    let pageProducts = []

    const products = await this.fetchPostsByComponent('page_product')

    const pageCategory = story?.content?.category ? story.content.category : ''

    if (products?.length > 0) {
      if (pageCategory) {
        pageProducts = products.filter((product) => {
          const productCategories =
            product.content.category && product.content.category.length > 0
              ? product.content.category
              : []

          return productCategories.includes(pageCategory)
        })
      } else {
        pageProducts = products
      }

      extraContent['products'] = pageProducts.map((product) =>
        parseStoryblokProductToAlgolia(product)
      )
    }

    this.setState({ extraContent: extraContent })
  }

  loadStory(init?: boolean) {
    this.storyblok
      .get(`cdn/stories/${getParam('path')}`, {
        version: 'draft',
        resolve_relations: resolve_relations.join(','),
      })
      .then(async (response: any) => {
        const { data } = response

        if (data && data.story) {
          const { content } = data?.story ? data.story : {}
          const { component } = content ? content : {}
          if (init) {
            if (component === 'page_category') {
              this.loadProductCategoryData(data.story)
            }

            if (component === 'page_article_category') {
              this.loadArticleCategoryData(data.story)
            }

            if (component === 'page_producer_list') {
              this.loadProducers()
              this.loadCountries()
            }

            if (component === 'page_country') {
              this.loadProducers(data.story.full_slug)
              this.loadRegions(data.story.full_slug)
            }

            if (component === 'page_region') {
              this.loadProducers(data.story.full_slug)
            }

            if (component === 'page_content') {
              this.loadContentNav(data.story)
            }

            if (component === 'page_product') {
              this.loadProductConfig(data.story.lang)
            }
          }
          this.setState({ story: data.story })
        }
      })
  }
  initStoryblokEvents() {
    this.loadStory(true)

    const sb = window.storyblok

    sb.on(['change', 'published'], (payload) => {
      this.loadStory()
    })

    sb.on('input', (payload: any) => {
      if (this.state.story && payload.story.id === this.state.story.id) {
        payload.story.content = sb.addComments(
          payload.story.content,
          payload.story.id
        )

        sb.resolveRelations(payload.story, resolve_relations, () => {
          const story = payload.story
          const preFetchedContent = this.state.story.content
          story.content = { ...preFetchedContent, ...story.content }
          this.setState({ story: story })
        })
      }
    })

    sb.pingEditor(() => {
      if (sb.inEditor) {
        sb.enterEditmode()
      }
    })
  }

  render() {
    const { story, extraContent } = this.state

    if (story == null) {
      return <div />
    }

    let content = story.content
    content = {
      ...content,
      ...extraContent,
    }

    return (
      <SbEditable content={content}>
        <div className={styles.editor}>
          {React.createElement(Components(story.content.component), {
            storyID: story.uuid,
            tags: story.tag_list,
            name: story.name,
            slug: story.slug,
            isStartPage: story.is_startpage,
            full_slug: story.full_slug,
            date: story.published_at,
            blok: content,
            preview: true,
            // footer: footer,
          })}
        </div>
      </SbEditable>
    )
  }
}

export default StoryblokEntry
